.slick-slider .slick-dots {
    bottom: 20px !important;
    z-index: 1;
}

.slick-slider .slick-dots li {
    margin: 0 3px;

    button {}
}

.slick-slider .slick-dots li button::before {
    font-size: 11px !important;
    color: #f8a832 !important;
}


.wrap_home {
    .explain {
        display: block;
        margin-top: 55px;

        >div {
            position: relative;
            height: 300px;

            >img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            >.wrap_explain_c {
                position: absolute;
                height: 100%;
                top: 0;
                left: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                z-index: 1;

                .explain_c {
                    // position: absolute;
                    padding: 15px 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 36%;
                    border-radius: 5px;
                    .ant-form-item-required {
                        display: none;
                    }

                    >h3 {
                        font-size: 32px;
                        font-weight: bold;
                        margin-bottom: 0;
                        margin-top: 35px;
                        text-transform: uppercase;
                        color: #fff;
                    }

                    >p {
                        color: #fff;
                        font-size: 17px;
                    }

                    .explain_c_form {
                        width: 400px;
                        margin-top: 30px;

                        form {
                            >.ant-form-item:nth-child(2) {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                            }

                            h5 {
                                margin-bottom: 10px;
                                font-size: 16px;
                                font-weight: bold;
                                color: #fff;
                            }

                            .ant-input-affix-wrapper {
                                background: transparent;
                                border: none;
                                border-radius: 0;
                                padding: 5px 0;
                                border-bottom: 1px solid #fff;
                                .ant-input-suffix {
                                    color: #fff;
                                    p {
                                        margin: 0px;
                                        cursor: pointer;
                                        font-size: 14px;
                                    }
                                }
                            }

                            .ant-input-affix-wrapper:focus-within {
                                border-bottom: 1px solid #fff;
                                border-top: 0 solid transparent;
                                border-right: 0 solid transparent;
                                border-left: 0 solid transparent;
                                box-shadow: none;
                            }

                            button {
                                background: #ff9900;
                                height: auto;
                                padding: 8px 35px;
                                font-weight: bold;
                            }

                            textarea {
                                height: 100px;
                            }

                            input:hover,
                            textarea:hover,
                            input:focus,
                            input:focus-within,
                            textarea:focus {
                                border: none;
                                border-color: transparent;
                            }
                            input::placeholder {
                                color: white;
                            }

                            input,
                            textarea {
                                background: transparent;
                                border: none;
                                border-radius: 0;
                                color: white;
                                width: 100%;

                            }
                        }
                    }

                }
            }

        }

        >div::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.7)
        }

    }

    .partner {
        text-align: center;
        background: #fafafa;

        h4 {
            font-size: 20px;
            font-weight: 700;
            padding-top: 60px;
            font-family: "Plus Jakarta Sans", sans-serif;
            margin-top: 0;

        }

        .wrapPartner {

            padding-bottom: 50px;

            .row {
                display: flex;
                flex-wrap: wrap;
                max-width: 75%;
                margin: 50px auto;

                >div {
                    flex: 0 0 auto;
                    width: 18.999999%;
                    padding: 0 30px;

                    img {
                        width: 100%;
                        height: 150px;
                    }
                }

                .row:nth-child(1) {
                    margin-top: 0 !important;
                }

            }
        }
    }

    .intro {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 55px;
        h2 {
            margin-top: 5px;
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 15px;
            text-transform: uppercase;
        }

        .cnt {
            margin-top: 0;
            margin-bottom: 25px;
            max-width: 65%;
            text-align: center;
            line-height: 22px;
        }

        .wrap-vid-intro {
            width: 65%;
            height: 450px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
            }

            a {
                position: absolute;
                z-index: 1;
            }
        }

        .wrap-vid-intro::after {
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            position: absolute;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            z-index: 0;
        }

        .play-btn {
            width: 70px;
            height: 70px;
            background: radial-gradient(rgba(3, 57, 108, 0.7) 60%, rgba(3, 57, 108, 1) 62%);
            border-radius: 50%;
            position: relative;
            top: 0;
            left: 47%;
            display: block;
            margin-top: 18%;
            box-shadow: 0px 0px 25px 3px rgba(3, 57, 108, 0.7);
        }

        /* triangle */
        .play-btn::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translateX(-40%) translateY(-50%);
            transform: translateX(-40%) translateY(-50%);
            transform-origin: center center;
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 25px solid #fff;
            border-radius: 5px;
            z-index: 100;
            -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
            transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        /* pulse wave */
        .play-btn:before {
            content: "";
            position: absolute;
            width: 150%;
            height: 150%;
            -webkit-animation-delay: 0s;
            animation-delay: 0s;
            -webkit-animation: pulsate1 2s;
            animation: pulsate1 2s;
            -webkit-animation-direction: forwards;
            animation-direction: forwards;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-timing-function: steps;
            animation-timing-function: steps;
            opacity: 1;
            border-radius: 50%;
            border: 5px solid rgba(255, 255, 255, .75);
            top: -31%;
            left: -31%;
            background: rgba(198, 16, 0, 0);
        }

        @-webkit-keyframes pulsate1 {
            0% {
                -webkit-transform: scale(0.6);
                transform: scale(0.6);
                opacity: 1;
                box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
            }

            100% {
                -webkit-transform: scale(1);
                transform: scale(1);
                opacity: 0;
                box-shadow: none;

            }
        }

        @keyframes pulsate1 {
            0% {
                -webkit-transform: scale(0.6);
                transform: scale(0.6);
                opacity: 1;
                box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
            }

            100% {
                -webkit-transform: scale(1, 1);
                transform: scale(1);
                opacity: 0;
                box-shadow: none;

            }
        }
    }
}

// .slick-slider::after {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(0, 0, 0, 0.3);
//     z-index: 0;
// }

.wrapbanner::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 0;
}

.wrapbanner {
    position: relative;

    img {
        position: relative;
        height: 530px;
        object-fit: cover;
        width: 100%;
    }



    .content_banner_home {
        position: absolute;
        z-index: 1;
        top: 33%;
        left: 40px;

        h1,
        h3,
        p,
        button {
            margin: 13px 0;

        }

        h1 {
            font-size: 48px;
            font-weight: 700;
            text-shadow: 0 13.36px 8.896px rgba(0, 0, 0, 0.7),0 -2px 1px #fff;
        }

        h3 {
            font-weight: 300;
            font-size: 44px;
        }

        h1,
        h3 {
            color: #fff;
        }

        p {
            color: #fff;
            font-size: 17px;
            font-weight: 700;
            max-width: 470px;
            font-family: "M PLUS Rounded 1c", sans-serif;

        }

        button {
            background-color: #ff9900;
            color: #fff;
            padding: 15px 45px;
            font-weight: bold;
            border: none;
            height: auto;
            border-radius: 10px;
            font-size: 15px;
            text-transform: uppercase;
        }

        button:hover {
            background-color: #f8a832;
            transition: all 0.4s ease;
            color: #f8a832 !important;
            border-color: #f8a832 !important;
        }
    }
}

.ant-menu .ant-menu-submenu-title {
    display: flex;
}



@media (max-width: 992px) {
    .main-l {
      >div {
        width: 100% !important;
      }
  
      >div:nth-child(n+2) {
        padding-left: 0;
        margin-top: 20px;
      }
    }
  
    .wrapMain {
      .wrapHeader {
        .banner {
          .banner_inf {
            justify-content: center;
          }
        }
      }
    }
  
    .wrapHeader .tabline ul {
      padding-right: 0 !important;
    }
  
    .wrapFot {
      >ul {
        flex-wrap: wrap;
        margin: 10px 0 !important;
        padding: 0 30px;
  
        >li {
          width: 100%;
          flex: 0 0 auto;
        }
  
        >li:nth-child(n+2) {
          margin-left: 0 !important;
          margin-top: 20px;
        }
      }
    }
    .wrap_home {
      .explain {
        >div {
          >.wrap_explain_c {
            .explain_c {
              width: 70% !important;
              text-align: center;
  
            }
          }
        }
      }
    }
  
    .wrap_home {
      .partner {
        .wrapPartner {
          .row {
            > div {
              flex: 0 0 auto;
              width: 48%;
              padding: 20px 5px;
            }
          }
        }
      }
    }
  
  }
  
  @media (max-width: 480px) {
    .wrap_home {
      .explain {
        >div {
          >.wrap_explain_c {
            .explain_c {
              width: 80% !important;
  
              >h3 {
                font-size: 18px !important;
              }
  
              .explain_c_form {
                width: 100% !important;
              }
  
              >p {
                font-size: 13px !important;
              }
  
            }
          }
        }
      }
  

    }
  
  }

  @media (max-width: 768px) {
    .wrap_home {
        .partner {
            .wrapPartner {
              .row {
                > div {
                  flex: 0 0 auto;
                  width: 98%;
                  padding: 20px 5px;
                }
              }
            }
          }
    }
  }
  