.wrapBlog {
    margin-bottom: 55px;
    .main-l {
        .blogins {
            .pagaWrap {
                margin-top: 20px;
            }
            .wrap-rol-smb {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                .rol-sm {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    padding: 20px;
                    border-radius: 5px;
                    margin-top: 15px;

                    >div {
                        h4 {
                            margin-bottom: 0;
                            font-size: 19px;
                            text-align: left;
                            font-weight: bold;
                            -webkit-line-clamp: 1;
                            cursor: pointer;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            margin-top: 0;

                        }

                        .tags {
                            margin: 10px 0;
                        }

                        >p {
                            margin: 0;
                            font-size: 15px;
                            font-weight: 300;
                            -webkit-line-clamp: 3;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }

                        >img {
                            width: 190px;
                            height: 121px;

                        }
                    }

                    >div:nth-child(2) {
                        padding-left: 25px;
                    }

                }
            }

            .rol-b {
                img {
                    width: 100%;
                    height: 300px;
                }

                >p {
                    font-size: 16px;
                    font-weight: 400;
                    margin-top: 7px;
                    -webkit-line-clamp: 3;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                >h1 {
                    font-size: 24px;
                    font-weight: 800;
                    margin-bottom: 0;
                    cursor: pointer;
                }

            }

            .tags {
                display: flex;
                margin: 20px 0;

                li {
                    display: flex;
                    align-items: center;

                    >div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 6px 14px;
                        background: #f1f0f1;
                        border-radius: 5px;

                        i {
                            font-size: 12px;
                            color: #03396c;
                        }

                        svg {
                            width: 12px;
                            height: 12px;
                            color: #03396c;
                        }

                        p {
                            margin: 0;
                            margin-left: 5px;
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                }

                li:nth-child(2) {
                    margin-left: 8px;
                }
            }
        }
        .wrapBlogDel {
            .ct-inf {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .in {
                    display: flex;
                    p {
                        font-size: 14px;
                        font-weight: 600;
                        color: #b5b5b5;
                        padding-right: 10px;
                        border-right: 1px solid #b5b5b5;
                    }
                    p:nth-child(2) {
                        padding-left: 10px;
                    }
                }
            }
            .tabhead {
                padding: 8px 10px;
                width:fit-content;
                border-radius: 5px;
                background: #ff9900;
                color: #fff;
                display: flex;
                justify-content: space-around;
                align-items: center;
                p {
                    margin: 0 7px;
                    font-size: 14px;
                    font-weight: bold;
                    
                }
                svg {
                    width: 14px;
                    height: 14px;
                }
            }
            img {
                width: 100%;
            }
            .desc {
                margin-top: 20px;
                p {
                    text-align: justify;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21px;
                    margin: 7px 0;
                }
                ul {
                    margin: 4px 0;
                    padding-left: 30px;

                    li {
                        list-style-type: circle;
                        margin-top: 3px;
                    }
                }
            }
            h1 {
                font-size: 30px;
                line-height: 24px;
                font-weight: 700;
                margin-bottom: 8px;
            }
        }
    }



}

@media (max-width: 576px) {
    .wrapBlog .main-l .wrapBlogDel .ct-inf  {
       flex-direction: column;
       justify-content: initial;
       align-items: flex-start;
    }
    
}
@media (max-width: 378px) {
    .wrapBlog .main-l .wrapBlogDel .ct-inf .in  {
        flex-direction: column;
        p:nth-child(2) {
            padding-left: 0;
            margin-top: 0;
        }
    }
    
}