.wrapContact {
    .wrapbanner {

        .content_banner_home {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            >p {
                margin-top: 0;
                margin-bottom: 20px;
                text-transform: none;
                font-size: 18px;
                text-align: center;

            }

        }
    }

    .box-contact {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;

        >div {
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            background: rgba(245, 245, 245, 1);
            width: 220px;
            padding: 20px;
            border-radius: 5px;

            .box-contact-ci {
                width: 33px;
                height: 33px;
                border-radius: 50%;
                background: #ff9900;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;

            }

            >h5 {
                margin-top: 10px;
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
                color: #111;
            }

            >p {
                margin-top: 10px;
                font-size: 15px;
                font-weight: 500;
                color: #111;
                text-transform: none;
            }

        }

        >div:nth-child(n+2) {
            margin-left: 20px;

        }
    }

    .wrapbanner::after {
        background-color: rgba(0, 0, 0, 0.5);
        height: 99%;
    }

    .wrapFormUn {
        max-width: 70%;
        margin: 45px auto;
        .box-form {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            >div,>form {
                width: 47.999999%;
            }
        }

        >div {
            width: 100%;

            iframe {
                height: 100%;
            }

            form {
                textarea {
                    height: 140px;

                }

                label {
                    font-size: 15px;
                    font-weight: bold;
                    line-height: 22px;
                    color: #333;
                }
                .btnSub {
                    background: #ff9900;
                    padding: 6px 29px;
                    height: 40px;
                    font-weight: bold;
                }
            }
        }
    }
}