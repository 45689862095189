
.layout-forgot .form-i:nth-child(1),
.layout-login .form-i:nth-child(2) {
    margin-bottom: 12px;
}

.layout-login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    width: 100%;
    .ant-form {
        width: 400px;
        padding: 0 20px;
    }
}


.form-btn-sm {
    font-size: 14px;
    color: #797979;
}

.form-btn-sm:hover {
    color: #174471;
}

.form-button-submit {
    width: 100%;
    background: #2C3E50;
    height: 37px;
}

.form-button-submit:hover {
    background: rgba(43, 61, 79, 0.8) !important;
    cursor: pointer;
}